import {useTranslation} from 'react-i18next'
import StatusCell from './StatusCell'
import MPointsCell from './MPointsCell'
import NoWrapCell from './NoWrapCell'
import DateCell from './DateCell'

const useColumns = () => {
  const {t} = useTranslation('billPayments')

  const columns = [
    {
      header: t('billPayments:billPayment.table.columns.headers.createdAt'),
      accessorKey: 'createdAt',
      cell: DateCell,
      enableSorting: true,
    },
    {
      header: t('billPayments:billPayment.table.columns.headers.payeeName'),
      accessorKey: 'payee.name',
      cell: NoWrapCell,
      enableSorting: true,
    },
    {
      header: t(
        'billPayments:billPayment.table.columns.headers.netPaymentAmount',
      ),
      accessorKey: 'netPaymentAmount',
      accessorFn: (rowData) => rowData.netPaymentAmount.toFormat(),
      enableSorting: true,
    },
    {
      header: t('billPayments:billPayment.table.columns.headers.feeAmount'),
      accessorKey: 'feeAmount',
      accessorFn: (rowData) =>
        rowData.grossPaymentAmount
          .subtract(rowData.netPaymentAmount)
          .toFormat(),
      enableSorting: true,
    },
    {
      header: t(
        'billPayments:billPayment.table.columns.headers.paymentReference',
      ),
      accessorKey: 'paymentReference',
      enableSorting: true,
    },
    {
      header: t('billPayments:billPayment.table.columns.headers.status'),
      accessorKey: 'status',
      enableSorting: true,
      cell: StatusCell,
    },
    {
      header: t('billPayments:billPayment.table.columns.headers.mPoints'),
      accessorFn: (rowData) => ({
        status: rowData.status,
        marmaladePointsEarnt: rowData.marmaladePointsEarnt,
      }),
      cell: MPointsCell,
    },
  ]

  return {columns}
}

export default useColumns
