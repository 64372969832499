import React from 'react'
import {useTranslation} from 'react-i18next'
import Alert from '../../Alert'

const SetupError = () => {
  const {t} = useTranslation('payments')

  return (
    <Alert
      status="error"
      title={t('payments:CreditCardForm.setupError.title')}
      message={t('payments:CreditCardForm.setupError.message')}
    />
  )
}

export default SetupError
