import React from 'react'
import propTypes from 'prop-types'
import {Box} from '@chakra-ui/react'

const LogoQantas = ({width, height, ...props}) => (
  <Box
    as="svg"
    width={width}
    height={height}
    viewBox="0 0 108 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_307_1315)">
      <path
        d="M29.1366 75.3246H107.531L44.6848 13.1335C36.3423 4.81698 24.9972 0.094834 13.131 0H0L0.979929 2.51082L5.35695 13.7773L29.1366 75.3246Z"
        fill="url(#paint0_linear_307_1315)"
      />
      <path
        d="M6.01331 13.2467C14.5913 27.7922 37.1821 29.3506 39.6704 52.013C39.778 52.9454 40.5026 53.6906 41.4384 53.8312C54.8724 55.9228 67.5171 61.476 78.1076 69.935H78.3695L78.6314 69.8052C78.7648 69.6573 78.7648 69.4336 78.6314 69.2857C70.9404 61.2708 61.6074 54.9825 51.2605 50.8441L48.0519 49.5454C46.3591 48.8907 45.2601 47.2559 45.3017 45.4545C45.4982 38.7013 61.6064 40.0649 63.2434 36.8182L63.5054 36.2338C60.1401 33.3809 56.1833 31.297 51.9153 30.1299C51.8498 30.3247 51.7843 31.1039 52.6356 32.5325C53.4868 33.961 51.5879 36.5584 48.6412 33.8961L48.3793 33.7013C26.6397 13.8961 10.2696 24.1558 1.62611 1.88312L6.01331 13.2467Z"
        fill="white"
      />
      <path
        d="M57.9221 36.2351C55.5575 36.1882 53.3899 34.9196 52.2078 32.8906C52.5124 33.4128 52.5095 34.057 52.2002 34.5765C51.8909 35.0959 51.3231 35.4102 50.7143 35.3989V40.7372C53.052 40.0941 55.7143 39.6438 57.9221 39.1936V36.2351Z"
        fill="url(#paint1_linear_307_1315)"
      />
      <path
        d="M43.7014 54.4366C56.038 56.7825 67.608 62.0819 77.4027 69.8726C54.7403 50.7706 44.4806 54.1794 43.7014 54.4366Z"
        fill="url(#paint2_linear_307_1315)"
      />
      <path
        d="M10.5195 14.8176V14.8176C7.01298 12.3092 4.02597 8.70749 1.62337 2.59741L5.97402 13.8528C14.4805 28.2597 36.8831 29.8033 39.3506 52.2498C36.8182 23.5646 24.1558 27.8095 10.5195 14.8176Z"
        fill="url(#paint3_linear_307_1315)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_307_1315"
        x1="121.996"
        y1="56.8897"
        x2="93.5469"
        y2="-26.5488"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BE0000" />
        <stop offset="0.39" stopColor="#DE0000" />
        <stop offset="0.77" stopColor="#F60000" />
        <stop offset="1" stopColor="#FF0000" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_307_1315"
        x1="57.6489"
        y1="41.4341"
        x2="58.4786"
        y2="33.5173"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.65" stopColor="white" stopOpacity="0.01" />
        <stop offset="0.69" stopColor="#EDEDED" stopOpacity="0.23" />
        <stop offset="0.76" stopColor="#D7D7D7" stopOpacity="0.5" />
        <stop offset="0.81" stopColor="#C6C6C6" stopOpacity="0.72" />
        <stop offset="0.87" stopColor="#B9B9B9" stopOpacity="0.87" />
        <stop offset="0.91" stopColor="#B2B2B2" stopOpacity="0.97" />
        <stop offset="0.95" stopColor="#AFAFAF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_307_1315"
        x1="43.7143"
        y1="69.9039"
        x2="77.4221"
        y2="69.9039"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.01" />
        <stop offset="0.07" stopColor="#F3F3F3" stopOpacity="0.15" />
        <stop offset="0.21" stopColor="#DEDEDE" stopOpacity="0.41" />
        <stop offset="0.35" stopColor="#CDCDCD" stopOpacity="0.62" />
        <stop offset="0.49" stopColor="#C0C0C0" stopOpacity="0.79" />
        <stop offset="0.63" stopColor="#B7B7B7" stopOpacity="0.91" />
        <stop offset="0.77" stopColor="#B1B1B1" stopOpacity="0.98" />
        <stop offset="0.9" stopColor="#AFAFAF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_307_1315"
        x1="1.61688"
        y1="52.1556"
        x2="39.2987"
        y2="52.1556"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.25" stopColor="white" stopOpacity="0.01" />
        <stop offset="0.36" stopColor="#F7F7F7" stopOpacity="0.11" />
        <stop offset="0.58" stopColor="#E1E1E1" stopOpacity="0.38" />
        <stop offset="0.88" stopColor="#BEBEBE" stopOpacity="0.82" />
        <stop offset="1" stopColor="#AFAFAF" />
      </linearGradient>
      <clipPath id="clip0_307_1315">
        <rect width="386" height="100" fill="white" />
      </clipPath>
    </defs>
  </Box>
)

LogoQantas.defaultProps = {
  width: 188,
  height: 148,
}
LogoQantas.propTypes = {
  width: propTypes.oneOfType([propTypes.number, propTypes.string]),
  height: propTypes.oneOfType([propTypes.number, propTypes.string]),
}
export default LogoQantas
