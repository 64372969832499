import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'

const useFormSchema = () => {
  const {t} = useTranslation('payments')
  Yup.setLocale({
    mixed: {
      required: t('common:yup.mixed.required'),
    },
    // use functions to generate an error object that includes the value from the schema
    string: {
      min: ({min}) => t('common:yup.string.min', {min}),
      max: ({max}) => t('common:yup.string.max', {max}),
      email: () => t('common:yup.string.email'),
    },
  })

  return Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    expirationMonth: Yup.string()
      .test(
        'validMonth',
        t('payments:CreditCardForm.fields.expirationMonth.invalid'),
        (value) => {
          const intValue = parseInt(value, 10)

          return intValue >= 1 && intValue <= 12
        },
      )
      .required(),
    expirationYear: Yup.string()
      .test(
        'validYear',
        t('payments:CreditCardForm.fields.expirationYear.invalid'),
        (value) => {
          const intValue = parseInt(value, 10)
          const currentDate = new Date()

          return intValue >= currentDate.getFullYear()
        },
      )
      .required(),
  })
}

export default useFormSchema
