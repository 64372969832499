import React from 'react'
import {useTranslation} from 'react-i18next'
import {Flex, Text} from '@chakra-ui/react'
import billPaymentPropType from '../../../utils/propTypes/billPayment'
import BillPaymentStatus from '../BillPaymentStatus'
import {toLocalDate, toLocalTime} from '../../../utils/formatter'
import RewardDetails from './RewardDetails'

const SummarySection = ({billPayment}) => {
  const {t} = useTranslation('billPayments')

  return (
    <Flex direction="column" gap="xs">
      <Flex justify="space-between" gap="xs">
        <Text textStyle="body-intro-medium">
          {t('billPayments:billPayment.drawer.summarySectionTitle')}
        </Text>
        <BillPaymentStatus status={billPayment.status} />
      </Flex>
      <Flex justify="end">
        <Text textStyle="body-small-medium">
          {`${toLocalDate(billPayment.createdAt, {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}, ${toLocalTime(billPayment.createdAt)}`}
        </Text>
      </Flex>
      <Flex justify="end">
        <RewardDetails
          status={billPayment.status}
          marmaladePointsEarnt={billPayment.marmaladePointsEarnt}
        />
      </Flex>
    </Flex>
  )
}

SummarySection.propTypes = {
  billPayment: billPaymentPropType.isRequired,
}

export default SummarySection
