import React from 'react'
import PropTypes from 'prop-types'

import {Box, Text, Link} from '@chakra-ui/react'

import CustomerPaymentBgImage from '../../../../assets/images/customer_payment_bg_screen.png'
import {LogoHorizontal, ThemeProvider, Payment} from '../../components'
import PaymentSuccess from './PaymentSuccess'
import InvoiceSummary from './InvoiceSummary'
import {useAuthenticityToken} from '../../hooks/authenticityToken'
import {retrieveInvoicePaymentInfo} from '../../../api'

const CustomerPaymentScreen = ({xeroInvoiceUUID, authenticityToken}) => {
  const [paymentInformation, setPaymentInformation] = React.useState({})

  const {authenticityToken: token} = useAuthenticityToken(authenticityToken)
  const [displaySuccessPayment, setDisplaySuccessPayment] =
    React.useState(false)
  const [customerDetails, setCustomerDetails] = React.useState({
    hasLoginAccount: false,
    hasAccount: false,
    email: '',
  })

  // Needs for show the invoice data.
  React.useEffect(() => {
    if (xeroInvoiceUUID) {
      retrieveInvoicePaymentInfo({xeroInvoiceUUID}).then((data) => {
        setPaymentInformation(data)
      })
    }
  }, [xeroInvoiceUUID])

  const {invoice, isCustomerLoggedIn, customerName} = paymentInformation

  const onSuccessDD = (result) => {
    setCustomerDetails({
      hasLoginAccount:
        (result.customer_account &&
          result.customer_account.has_login_account) ||
        false,
      email:
        (result.customer_account && result.customer_account.customer_email) ||
        '',
      hasAccount:
        (result.customer_account && result.customer_account.has_account) ||
        false,
    })

    setDisplaySuccessPayment(true)
  }

  const onSuccessCC = (result) => {
    if (result) {
      // The cybersource CC flow does not pass on customer_details currently
      setCustomerDetails({
        hasLoginAccount:
          (result.customer_account &&
            result.customer_account.has_login_account) ||
          false,
        email:
          (result.customer_account && result.customer_account.customer_email) ||
          '',
        hasAccount:
          (result.customer_account && result.customer_account.has_account) ||
          false,
      })
    }

    setDisplaySuccessPayment(true)
  }

  return (
    <ThemeProvider>
      <Box
        width="100%"
        background={`url("${CustomerPaymentBgImage}")`}
        minHeight="100vh"
        px="sm"
        py="lg"
      >
        <Box
          padding="lg"
          backgroundColor="neutral.white"
          maxWidth="lg"
          margin="0 auto"
          borderRadius="lg"
          boxShadow="0px 2px 4px rgba(23, 27, 30, 0.1)"
        >
          <LogoHorizontal width={295} height={34} />
          {displaySuccessPayment ? (
            <PaymentSuccess
              key={customerDetails.email || invoice.supplierName}
              supplierName={invoice.supplierName}
              customerDetails={customerDetails}
              authenticityToken={token}
              isLoggedIn={isCustomerLoggedIn}
            />
          ) : (
            <>
              {isCustomerLoggedIn && (
                <Box d="flex" justifyContent="space-between" p="sm" pl="0">
                  {customerName && <Text>Welcome back {customerName}</Text>}
                  <Text>
                    Go to{' '}
                    <Link color="secondary.blue" href="/customer-invoices">
                      dashboard
                    </Link>
                  </Text>
                </Box>
              )}

              {!isCustomerLoggedIn && (
                <Box p="sm" pl="0">
                  Have an account?{' '}
                  <a href="/customer_logins/sign_in">Sign in</a>
                </Box>
              )}
              {invoice && (
                <InvoiceSummary
                  invoice={{
                    id: invoice.id,
                    xeroInvoiceId: invoice.xeroInvoiceId,
                    number: invoice.number,
                    amount: invoice.amount,
                    dueAmount: invoice.dueAmount,
                    appliedCreditNoteAmount: invoice.appliedCreditNoteAmount,
                    invoicePaymentsAmount: invoice.invoicePaymentsAmount,
                    supplierName: invoice.supplierName,
                    dueDate: invoice.formattedDueDate,
                    dueInDays: invoice.dueInDays,
                    isPaid: invoice.isPaid,
                    isVoided: invoice.isVoided,
                    pdfAvailable: invoice.pdfAvailable,
                  }}
                />
              )}
              <Payment
                onSuccessDD={onSuccessDD}
                onSuccessCC={onSuccessCC}
                xeroInvoiceUUID={xeroInvoiceUUID}
                authenticityToken={token}
              />
            </>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

CustomerPaymentScreen.propTypes = {
  xeroInvoiceUUID: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
}

export default CustomerPaymentScreen
