import React from 'react'
import PropTypes from 'prop-types'
import {Box, Flex, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import SurchargeSection from './SurchargeSection'
import {PadlockIcon} from '../../../icons'
import CardSection from './CardSection'
import useCreditCardForm from './useCreditCardForm'
import SubmitButton from './SubmitButton'
import SetupError from './SetupError'

const CybersourceCreditCardForm = ({
  payerCreditCardSurcharged,
  creditCardSurchargePercentage,
  creditCardSurchargeFixedAmount,
  creditCardPaymentAmount,
  dueAmount,
  creditCardSurchargeAmount,
  onSubmitPayment,
  authenticityToken,
  invoiceId,
}) => {
  const {t} = useTranslation('payments')

  const {
    formik,
    cardDetails,
    cardNumberDetails,
    cardCvcDetails,
    acceptedCardBrands,
    isLoadingForm,
    isValid,
    submitErrorMessage,
    setupError,
  } = useCreditCardForm(invoiceId, authenticityToken, onSubmitPayment)

  return (
    <Flex
      as="form"
      direction="column"
      gap="sm"
      mt="md"
      onSubmit={formik.handleSubmit}
    >
      <SurchargeSection
        payerCreditCardSurcharged={payerCreditCardSurcharged}
        creditCardSurchargePercentage={creditCardSurchargePercentage}
        creditCardSurchargeFixedAmount={creditCardSurchargeFixedAmount}
        creditCardPaymentAmount={creditCardPaymentAmount}
        dueAmount={dueAmount}
        creditCardSurchargeAmount={creditCardSurchargeAmount}
      />
      {setupError ? (
        <SetupError />
      ) : (
        <CardSection
          isLoading={isLoadingForm}
          formik={formik}
          cardDetails={cardDetails}
          cardNumberDetails={cardNumberDetails}
          cardCvcDetails={cardCvcDetails}
          acceptedCardBrands={acceptedCardBrands}
        />
      )}

      {submitErrorMessage && (
        <Box>
          <Text mb="sm" textStyle="body-detail" color="mld.alert.700">
            {submitErrorMessage}
          </Text>
        </Box>
      )}
      <Flex fontSize="14px" color="neutral.80" alignItems="center">
        {t('payments:CreditCardForm.securityMessage')}{' '}
        <PadlockIcon ml="xs" size="24px" />
      </Flex>
      <SubmitButton
        isSubmitting={formik.isSubmitting}
        isDisabled={!isValid}
        creditCardPaymentAmount={creditCardPaymentAmount}
      />
    </Flex>
  )
}

CybersourceCreditCardForm.defaultProps = {
  onSubmitPayment: () => {},
}

CybersourceCreditCardForm.propTypes = {
  onSubmitPayment: PropTypes.func,
  payerCreditCardSurcharged: PropTypes.bool.isRequired,
  creditCardSurchargePercentage: PropTypes.number.isRequired,
  creditCardSurchargeFixedAmount: PropTypes.string.isRequired,
  creditCardPaymentAmount: PropTypes.string.isRequired,
  dueAmount: PropTypes.string.isRequired,
  creditCardSurchargeAmount: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
}

export default CybersourceCreditCardForm
