import React from 'react'
import PropTypes from 'prop-types'
import {Trans, useTranslation} from 'react-i18next'
import {Flex, Text} from '@chakra-ui/react'
import LogoSymbol from '../Logo/LogoSymbol'
import LogoQantas from '../Logo/LogoQantas'

const RewardDetails = ({status, marmaladePointsEarnt}) => {
  const {t} = useTranslation('billPayments')
  if (status === 'failed') {
    return (
      <Text textStyle="body-small">
        {t('billPayments:billPayment.drawer.noMPointsEarned')}
      </Text>
    )
  }

  if (status !== 'successful') {
    return null
  }

  return (
    <Flex direction="column" gap="xxxs" align="end">
      <Flex align="center" justify="end" gap="xs">
        <LogoSymbol width="30px" height="30px" />
        <Text textStyle="body-small-medium">
          {t('billPayments:billPayment.drawer.mPointsEarned', {
            marmaladePointsEarnt,
          })}
        </Text>
      </Flex>
      <Flex
        align="center"
        justify="end"
        gap="xxs"
        textStyle="body-detail"
        color="mld.neutral.800"
      >
        <Trans
          t={t}
          i18nKey="billPayments:billPayment.drawer.qantasPointsRate"
          components={{
            qantasLogo: <LogoQantas width="15px" height="15px" />,
          }}
        />
      </Flex>
      <Text textStyle="body-detail" color="mld.neutral.800">
        {t('billPayments:billPayment.drawer.otherPartners')}
      </Text>
    </Flex>
  )
}

RewardDetails.propTypes = {
  status: PropTypes.string.isRequired,
  marmaladePointsEarnt: PropTypes.number.isRequired,
}

export default RewardDetails
