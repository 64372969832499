import React from 'react'
import PropTypes from 'prop-types'
import {Box, Flex} from '@chakra-ui/react'
import CyberSourceField from './CybersourceField'
import {LogoAmex, LogoMastercard, LogoVisa} from '../../Logo'
import CardLogo from './CardLogo'
import FormikField from './FormikField'

const CardSection = ({
  formik,
  isLoading,
  cardDetails,
  cardNumberDetails,
  cardCvcDetails,
  acceptedCardBrands,
}) => {
  return (
    <Flex direction="column" gap="sm">
      <Flex alignItems="flex-start" justifyContent="space-between" gap="sm">
        <FormikField
          isLoading={isLoading}
          id="firstName"
          name="firstName"
          type="text"
          formik={formik}
        />
        <FormikField
          isLoading={isLoading}
          id="lastName"
          name="lastName"
          type="text"
          formik={formik}
        />
      </Flex>
      <CyberSourceField
        name="cardNumber"
        value={cardNumberDetails}
        errorMessage={cardNumberDetails.errorMessage}
        focusInput={cardNumberDetails.focusInput}
        isLoading={isLoading}
        iframeIdentifier="card-number-container"
      />
      <Flex alignItems="flex-start" justifyContent="space-between" gap="sm">
        <FormikField
          isLoading={isLoading}
          name="expirationMonth"
          maxLength="2"
          formik={formik}
        />
        <FormikField
          isLoading={isLoading}
          name="expirationYear"
          maxLength="4"
          formik={formik}
        />
        <CyberSourceField
          name="cardCvc"
          value={cardCvcDetails}
          errorMessage={cardCvcDetails.errorMessage}
          focusInput={cardCvcDetails.focusInput}
          isLoading={isLoading}
          iframeIdentifier="card-cvc-container"
          width={{base: '50%', md: '100%'}}
        />
      </Flex>
      <Box display="flex" alignItems="center" mb="sm">
        <CardLogo
          LogoComponent={LogoMastercard}
          detectedBrandName={cardDetails?.name}
          accepted={acceptedCardBrands.includes('MASTERCARD')}
          cardBrandName="mastercard"
        />
        <CardLogo
          LogoComponent={LogoVisa}
          detectedBrandName={cardDetails?.name}
          accepted={acceptedCardBrands.includes('VISA')}
          cardBrandName="visa"
        />
        <CardLogo
          LogoComponent={LogoAmex}
          detectedBrandName={cardDetails?.name}
          accepted={acceptedCardBrands.includes('AMEX')}
          cardBrandName="amex"
        />
      </Box>
    </Flex>
  )
}

CardSection.defaultProps = {
  cardDetails: null,
}

CardSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  cardDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  cardNumberDetails: PropTypes.shape({
    focusInput: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
  }).isRequired,
  cardCvcDetails: PropTypes.shape({
    focusInput: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
  acceptedCardBrands: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default CardSection
